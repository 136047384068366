import Layout from '../components/Layout';
import { Link } from 'gatsby';
import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import React from 'react';
import { Box } from '@3pc/layout-components-react';
import { headerHeight } from '../components/PageHeader';
import { footerHeight } from '../components/Footer';
import styled from '@emotion/styled';
import { colors } from '../styles/colors';
import { devices } from '../styles/breakpoints';
import useStaticLogos from '../hooks/use-logos';
import { GatsbyImage } from 'gatsby-plugin-image';

const Heading = styled.h2`
  line-height: 1.3125em;
  margin-top: 30px;

  &:first-of-type {
    margin-top: 0;
  }
`;

const Paragraph = styled.p`
  margin-top: 10px;
  line-height: 1.3125em;
`;

const Address = styled.address`
  margin-top: 10px;
  font-size: 0.875em;
  line-height: 1.3125em;
  font-style: normal;

  @media ${devices.mobileMin} {
    font-size: 1em;
  }
`;

const LogoContainer = styled.div`
  width: 100px;
  margin-top: 20px;

  & div[data-gatsby-image-wrapper] {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media ${devices.mobileMin} {
    width: 140px;
    margin-top: 20px;
  }
`;

const PrivacyLink = styled(Link)`
  margin-top: 30px;
  display: inline-block;
  color: ${colors.black};
  font-size: 0.875em;
  line-height: 1em;
  font-weight: bold;
  border-bottom: 2px solid ${colors.blue};

  @media ${devices.mobileMin} {
    font-size: 1em;
  }
`;

const ExLink = styled.a`
  border-bottom: 2px solid ${colors.blue};
  color: ${colors.black};
`;

const AboutPageTemplate = () => {
  const {
    fuldaLogo,
    hlzLogo,
    vonderauLogo,
    dhLogo,
    dmLogo,
    dachmarkeLogo,
  } = useStaticLogos();

  return (
    <>
      <PageHeader headerTitle="Impressum" pageType={'sublist-page'} />

      <Layout>
        <Box
          mt={headerHeight.headerMobile}
          mb={footerHeight.footerMobile}
          p="20px"
          pt={['22px', '32px']}
          mx={['auto', '18%']}
          maxWidth="553px"
        >
          <Heading>Angaben gemäß § 5 TMG:</Heading>
          <Address>
            Hessische Landeszentrale für politische Bildung <br />
            Taunusstraße 4-6 <br />
            65183 Wiesbaden
          </Address>
          <Heading>Vertreten durch:</Heading>
          <Paragraph>Dr. Alexander Jehn, Direktor</Paragraph>
          <Heading>Kontakt:</Heading>
          <Address>
            Telefon: <ExLink href="tel:+4961132554051">0611-3255-4051</ExLink>{' '}
            <br />
            Telefax: <ExLink href="fax:+4961132554077">
              0611-3255-4077
            </ExLink>{' '}
            <br />
            E-Mail:{' '}
            <ExLink href="mailto:poststelle@hlz.hessen.de">
              poststelle@hlz.hessen.de
            </ExLink>{' '}
          </Address>
          <Heading>
            Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
          </Heading>
          <Address>
            Dr. Alexander Jehn <br />
            Taunusstraße 4-6 <br />
            65183 Wiesbaden
          </Address>
          <Heading>Projektleitung</Heading>
          <Address>
            Magistrat der Stadt Fulda <br />
            Vonderau Museum <br />
            Katja Galinski <br />
            Jesuitenplatz 2 <br />
            36037 Fulda <br /> <br />
            Telefon: <ExLink href="tel:+496611023210">
              0661-102-3210
            </ExLink>{' '}
            <br />
            E-Mail:{' '}
            <ExLink href="mailto:museum@fulda.de">museum@fulda.de</ExLink>
            <br />
            Web:{' '}
            <ExLink
              href="https://www.museum-fulda.de"
              rel="noopener noreferrer"
              target="_blank"
            >
              www.museum-fulda.de
            </ExLink>{' '}
          </Address>

          <LogoContainer>
            <GatsbyImage
              image={vonderauLogo.childImageSharp.gatsbyImageData}
              alt="logo Vonderau Museum"
            />
            <GatsbyImage
              image={fuldaLogo.childImageSharp.gatsbyImageData}
              alt="logo Fulda"
            />
          </LogoContainer>

          <Heading>Konzeption, Dramaturgie, Redaktion, Text</Heading>
          <Paragraph>
            beier+wellach projekte, Berlin <br />
            Peter Wellach <br />
            Francesca Belli <br />
            Julia Bürger
          </Paragraph>

          <Heading>Design und Programmierung</Heading>
          <Paragraph>3pc GmbH Neue Kommunikation, Berlin</Paragraph>

          <Heading>Gefördert durch:</Heading>
          <LogoContainer>
            <GatsbyImage
              image={hlzLogo.childImageSharp.gatsbyImageData}
              alt="logo HLZ"
            />
            <GatsbyImage
              image={dachmarkeLogo.childImageSharp.gatsbyImageData}
              alt="logo 75 Jahre Dachmarke"
            />
            <GatsbyImage
              image={dmLogo.childImageSharp.gatsbyImageData}
              alt="logo Digitalministerium"
            />
            <GatsbyImage
              image={dhLogo.childImageSharp.gatsbyImageData}
              alt="logo digitales hessen"
            />
          </LogoContainer>

          <Paragraph>
            Die App zur Hessischen Verfassung ist im Rahmen der
            Jubiläumsausstellung &quot;Als die Demokratie zurückkam – 75 Jahre
            Verfassung in Hessen und Fulda&quot; (15. Juli bis 24. Oktober 2021)
            am Vonderau Museum Fulda entstanden.
          </Paragraph>

          <Heading>Realisierung und Pflege</Heading>
          <Paragraph>
            <ExLink href="https://xisio.com/">
              Xisio Informationsysteme GmbH
            </ExLink>
          </Paragraph>
          <Paragraph>
            Kontakt: Michael Ibsen,{' '}
            <ExLink href="mailto:michael.ibsen@xisio.com">
              michael.ibsen@xisio.com
            </ExLink>
          </Paragraph>

          <Heading>Streitschlichtung</Heading>
          <Paragraph>
            Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.
          </Paragraph>

          <Heading>Haftung für Inhalte</Heading>
          <Paragraph>
            Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte
            auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach
            §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht
            verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
            überwachen oder nach Umständen zu forschen, die auf eine
            rechtswidrige Tätigkeit hinweisen.
          </Paragraph>
          <Paragraph>
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
          </Paragraph>

          <Heading>Urheberrecht</Heading>
          <Paragraph>
            Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
            diesen Seiten unterliegen dem deutschen Urheberrecht. Die
            Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
            Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
            schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
            Downloads und Kopien dieser Seite sind nur für den privaten, nicht
            kommerziellen Gebrauch gestattet.
          </Paragraph>
          <Paragraph>
            Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt
            wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
            werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
            trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten
            wir um einen entsprechenden Hinweis. Bei Bekanntwerden von
            Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
          </Paragraph>

          <Heading>Rechte Dritter</Heading>
          <Paragraph>
            An Bildern, Grafiken, Texten, Marken oder sonstigen Werken und
            Leistungen können ganz oder teilweise Rechte Dritter bestehen.
          </Paragraph>

          <PrivacyLink to="/datenschutzerklaerung">
            Datenschutzerklärung
          </PrivacyLink>
        </Box>
      </Layout>

      <Footer />
    </>
  );
};

export default AboutPageTemplate;
